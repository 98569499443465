import { Router } from '@/Components'
import { MarketingLayout } from '@/Layouts'
import { Head, usePage } from '@inertiajs/react'
import { ReactNode, useMemo } from 'react'

// This relates to valid definitions returned in the DefinitionController
export enum Definitions {
  smugllionaire = 'smugllionaire',
  tfsayayness = 'tfsayayness',
  investavert = 'investavert',
  richtirement = 'richtirement'
}

type Props = {
  word: Definitions
}

Page.layout = (page: ReactNode) => <MarketingLayout>{page}</MarketingLayout>

export default function Page() {
  const { word } = usePage<Props>().props

  const title = useMemo(() => {
    switch (word) {
      case Definitions.smugllionaire:
        return 'Smug-llionaire'
      case Definitions.tfsayayness:
        return 'tfsa-yayness'
      case Definitions.investavert:
        return 'invest-a-vert'
      case Definitions.richtirement:
        return 'rich-tirement'
      default:
        return 'Fynbos'
    }
  }, [word])

  return (
    <>
      <Head title={title} />
      <Definition />
      <CTA />
    </>
  )
}

function Definition() {
  const { word } = usePage<Props>().props

  switch (word) {
    case Definitions.smugllionaire:
      return <Smugllionaire />
    case Definitions.tfsayayness:
      return <Tfsayayness />
    case Definitions.investavert:
      return <Investavert />
    case Definitions.richtirement:
      return <Richtirement />
    default:
      return null
  }
}

function Smugllionaire() {
  return (
    <section className='flex min-h-[calc(100svh-300px)] w-full items-center bg-rose-500 p-4 text-white md:min-h-[calc(100dvh-320px)] lg:min-h-[calc(100dvh-320px)]'>
      <div className='mx-auto flex w-full max-w-5xl flex-col sm:flex-row'>
        <div className='contents justify-center sm:flex sm:flex-col'>
          <h1 className='flex flex-col text-6xl font-black uppercase leading-none text-yellow-300 sm:text-8xl'>
            Smug-llionaire
          </h1>
          <dfn
            title='Smug-llionaire'
            className='mt-4 max-w-prose text-2xl not-italic'
          >
            [ smuhg-l-y<span className='italic'>uh</span>-
            <span className='font-semibold'>nair</span> ]
          </dfn>
          <p className='font-semibold italic'>noun</p>
          <p className='mt-4 max-w-prose px-4'>
            When you become wealthy from investments you made when you where
            younger and the enormous self-satisfaction you gain from having made
            those smart decisions.
          </p>
        </div>
      </div>
    </section>
  )
}

function Investavert() {
  return (
    <section className='flex min-h-[calc(100svh-300px)] w-full items-center bg-orange-500 p-4 text-white md:min-h-[calc(100dvh-320px)] lg:min-h-[calc(100dvh-320px)]'>
      <div className='mx-auto flex w-full max-w-5xl flex-col sm:flex-row'>
        <div className='contents justify-center sm:flex sm:flex-col'>
          <h1 className='flex flex-col text-6xl font-black uppercase leading-none text-yellow-300 sm:text-8xl'>
            Invest-a-vert
          </h1>
          <dfn
            title='Smug-llionaire'
            className='mt-4 max-w-prose text-2xl not-italic'
          >
            [ <span className='font-semibold'>in</span>-vest-a-vurt ]
          </dfn>
          <p className='font-semibold italic'>noun</p>
          <p className='prose mt-4 max-w-prose px-4 text-2xl text-white prose-li:marker:text-white'>
            A person who relishes both:
            <ol>
              <li>
                the introverted inner peace from making clever investment
                choices AND
              </li>
              <li>
                the extroverted swagger of getting rich off your clever
                investment choices
              </li>
            </ol>
          </p>
        </div>
      </div>
    </section>
  )
}

function Tfsayayness() {
  return (
    <section className='flex min-h-[calc(100svh-300px)] w-full items-center bg-indigo-500 p-4 text-white md:min-h-[calc(100dvh-320px)] lg:min-h-[calc(100dvh-320px)]'>
      <div className='mx-auto flex w-full max-w-5xl flex-col sm:flex-row'>
        <div className='contents justify-center sm:flex sm:flex-col'>
          <h1 className='flex flex-col text-6xl font-black uppercase leading-none text-yellow-300 sm:text-8xl'>
            TFSA-yayness
          </h1>
          <dfn
            title='Smug-llionaire'
            className='mt-4 max-w-prose text-2xl not-italic'
          >
            [ tee-<span className='font-semibold'>ef</span>-es-a-yey-nis ]
          </dfn>
          <p className='font-semibold italic'>noun</p>
          <p className='mt-4 max-w-prose px-4'>
            A heightened sense of elation on maxing out your Tax Free Savings
            Account at R36 000. Often accompanied with the phrase &apos;Whoop,
            whoop!&apos; and &apos;YEAH!&apos;.
          </p>
        </div>
      </div>
    </section>
  )
}

function Richtirement() {
  return (
    <section className='flex min-h-[calc(100svh-300px)] w-full items-center bg-purple-500 p-4 text-white md:min-h-[calc(100dvh-320px)] lg:min-h-[calc(100dvh-320px)]'>
      <div className='mx-auto flex w-full max-w-5xl flex-col sm:flex-row'>
        <div className='contents justify-center sm:flex sm:flex-col'>
          <h1 className='flex flex-col text-6xl font-black uppercase leading-none text-yellow-300 sm:text-8xl'>
            Rich-tirement
          </h1>
          <dfn
            title='Smug-llionaire'
            className='mt-4 max-w-prose text-2xl not-italic'
          >
            [ rich-<span className='font-semibold'>tahyuhr</span>-mu
            <span className='italic'>h</span>nt ]
          </dfn>
          <p className='font-semibold italic'>noun</p>
          <p className='mt-4 max-w-prose px-4'>
            Reaching retirement and becoming rich at the same time because of
            the clever money choices you made when you were young.
          </p>
        </div>
      </div>
    </section>
  )
}

function CTA() {
  const { word } = usePage<Props>().props

  const title = useMemo(() => {
    switch (word) {
      case Definitions.smugllionaire:
        return (
          <>
            Start your journey to becoming a{' '}
            <span className='font-medium'>smug-llionaire</span> with a Fynbos
            tax-free savings account.
          </>
        )
      case Definitions.tfsayayness:
        return (
          <>
            Start your journey to{' '}
            <span className='font-medium'>TFSA-yayness</span> with a Fynbos
            tax-free savings account.
          </>
        )
      case Definitions.investavert:
        return (
          <>
            Start your journey to becoming an{' '}
            <span className='font-medium'>invest-a-vert</span> with a Fynbos
            tax-free savings account.
          </>
        )
      case Definitions.richtirement:
        return (
          <>
            Start your journey to{' '}
            <span className='font-medium'>rich-tirement</span> with a Fynbos
            tax-free savings account.
          </>
        )
      default:
        return ''
    }
  }, [word])

  return (
    <section id='pricing' className='bg-white p-4 py-20 sm:py-40'>
      <div className='mx-auto flex w-full max-w-5xl flex-col items-center justify-center gap-10 text-pretty'>
        <h4 className='text-center text-2xl text-medium'>{title}</h4>
        <Router
          href={route('marketing.home')}
          className='flex w-full items-center justify-center rounded-full bg-blue-500 p-6 text-center hover:bg-blue-600 sm:max-w-max sm:px-20'
        >
          <span className='mx-auto text-lg font-medium leading-none text-on-color lg:text-xl'>
            Learn more about Fynbos Money
          </span>
        </Router>
      </div>
    </section>
  )
}
